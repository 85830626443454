.SideBar {
  grid-area: "SideBar";
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-right: 1px solid var(--text-light);
  background-color: var(--gradient-cream);
  font-size: var(--fontSizeSmall);
  border-left: 20px solid black;
}

.SideBar > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  font-size: var(--fontSizeSmall);
}

.SideBar__item {
  color: var(--text-dark);
  background-color: var(--gradient-cream);
  transition: background-color, color 0.6s;
  padding: 5px;
  margin-bottom: 5px;
  /* border-bottom: 1px solid black; */
}

.SideBar__item:hover {
  background-color: var(--gradient-dark);
  color: var(--text-light);
}

.SideBar__item-active {
  background-color: var(--gradient-dark);
  color: var(--text-light);
}

.Separator {
  border-bottom: 10px solid black;
}
