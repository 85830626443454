.Resume {
  background-color: var(--gradient-cream);
  animation: fadeIn 2s;
  height: 1600px;
}

.Resume p {
  margin: 20px;
  text-align: center;
  font-size: var(--fontSizeLarge);
}

.Resume__container {
  margin: 0px;
  position: relative;
  text-align: center;
  align-content: center;
  cursor: pointer;
}
