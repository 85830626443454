.Contact {
  display: grid;
  grid-template-rows: repeat(auto-fill, minmax(30px, auto));
  grid-template-areas: "RecommendationCardContainer BusinessCardContainer";
  animation: fadeIn 2s;
  /* padding: 30px; */
}

.RecommendationCardContactContainer {
  grid-area: "RecommendationCardContainer";
  padding-right: 50px;
}

.BusinessCardContactContainer {
  grid-area: "BusinessCardContainer";
  max-height: min-content;
}

.RecommendationCardContactContainer h1 {
  text-align: center;
  font-size: var(--fontSizeLarge);
  font-weight: bold;
}
