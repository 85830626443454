.NavBarContainer {
  background-image: url("../../img/sunset-beach.png");
  background-size: 105%;
  background-repeat: no-repeat;
  background-color: black;
}

.NavBar__header {
  background-color: transparent;
  text-align: center;
  font-size: var(--fontSizeHeaderLarge);
  color: white;
  margin: 10px 0px 0px 0px;
}

.NavBar {
  display: flex;
  grid-area: "NavBar";
  justify-content: center;
  background-color: transparent;
  margin-bottom: 10px;
}

.NavBar__header p {
  margin: 0px;
  font-family: "RalewayThin", sans-serif;
  padding: 0px;
  font-weight: 100;
  letter-spacing: 8px;
  text-shadow: 1px 1px #000000;
}

.NavBar__location {
  font-size: var(--fontSizeMedium);
  color: white;
  font-weight: 500;
  text-shadow: 1px 1px var(--background-light);
}

.NavBar__titles {
  font-size: var(--fontSizeMedium);
  color: white;
  font-weight: 500;
  margin-bottom: 10px;
  text-shadow: 1px 1px var(--background-light);
}

.NavBar__item {
  font-size: var(--fontSizeMediumPlus);
  transition: color 0.3s;
  background-color: transparent;
  color: var(--background-dark);
  margin: 10px;
  text-shadow: 1px 1px var(--background-light);
}
.NavBar__item img {
  background-color: black;
}

.NavBar__item:hover {
  font-size: var(--fontSizeMediumPlus);
  margin: "10px";
  color: white;
}

.NavBar__item-active {
  color: white;
}
