.BusinessCardContainer {
  font-size: var(--fontSizeMedium);
  /* text-shadow: -1px 1px 0px grey; */
  animation: fadeIn 2s;
  grid-area: "BusinessCard";
  margin: 40px;
}

.BusinessCard__HeadshotContainer {
  /* left: 10;
  top: 10; */
  margin-left: 5px;
  position: absolute;
}

.BusinessCard__Headshot {
  width: 300px;
  border-radius: 0%;
  margin-top: 5px;
}

.BusinessCard {
  position: relative;
  font-family: "Calibri";
  margin: auto;
  height: 350px;
  width: 650px;
  padding-right: 10px;
  box-shadow: -5px 10px 10px #2a2929;
  background-image: url("../../img/card-texture.png");
  background-repeat: repeat-y;
  background-attachment: scroll;
  background-size: 100%;
  border-radius: 2%;
  transition: height 1s, width 1s, background-size 1s, font-size 1s;
}

.BusinessCard a {
  color: rgb(4, 4, 114);
}

.BusinessCard__UpperCenter {
  text-align: right;
  padding-top: 50px;
  font-size: var(--fontSizeMediumPlus);
}

/* .BusinessCard:hover {
  height: 260px;
  width: 580px;

  background-size: 110%;
} */

.BusinessCard__RightUpperCorner {
  position: absolute;
  height: 0;
  width: 0;
  right: 0;
  border-top: 50px solid var(--background-dark);
  border-left: 50px solid transparent;
}

.BusinessCard__MidCenter {
  margin-top: 13%;
  font-size: var(--fontSizeMedium);
}

.BusinessCard__MidCenter p {
  font-size: var(--fontSizeMedium);
  text-align: right;
  margin: 0px;
}

.BusinessCard__Bottom {
  margin-top: 10px;
  text-align: right;
  font-size: var(--fontSizeMedium);
}

.BusinessCard__Bottom a {
  text-align: right;
  margin: 0px 10px;
}

.BusinessCard__Bottom p {
  text-align: right;
  margin: 0px;
}
