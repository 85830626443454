@font-face {
  font-family: "Cambria Math";
  panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
  font-family: Calibri;
  panose-1: 2 15 5 2 2 2 4 3 2 4;
}
@font-face {
  font-family: Georgia;
  panose-1: 2 4 5 2 5 4 5 2 3 3;
}
@font-face {
  font-family: Constantia;
  panose-1: 2 3 6 2 5 3 6 3 3 3;
}
@font-face {
  font-family: "Noto Sans Symbols";
}

.ResumeContainer {
  margin: auto;
  max-width: 780px;
  text-align: center;
}

.Resume__Summary {
  width: "700px";
}

/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
  margin: 0in;
  margin-bottom: 0.0001pt;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
h1 {
  margin-top: 24pt;
  margin-right: 0in;
  margin-bottom: 6pt;
  margin-left: 0in;
  page-break-after: avoid;
  font-size: 24pt;
  font-family: "Calibri", sans-serif;
}
h2 {
  margin-top: 0.25in;
  margin-right: 0in;
  margin-bottom: 4pt;
  margin-left: 0in;
  page-break-after: avoid;
  font-size: 18pt;
  font-family: "Calibri", sans-serif;
}
h3 {
  margin-top: 14pt;
  margin-right: 0in;
  margin-bottom: 4pt;
  margin-left: 0in;
  page-break-after: avoid;
  font-size: 14pt;
  font-family: "Calibri", sans-serif;
}
h4 {
  margin-top: 12pt;
  margin-right: 0in;
  margin-bottom: 2pt;
  margin-left: 0in;
  page-break-after: avoid;
  font-size: 12pt;
  font-family: "Calibri", sans-serif;
}
h5 {
  margin-top: 11pt;
  margin-right: 0in;
  margin-bottom: 2pt;
  margin-left: 0in;
  page-break-after: avoid;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
h6 {
  margin-top: 10pt;
  margin-right: 0in;
  margin-bottom: 2pt;
  margin-left: 0in;
  page-break-after: avoid;
  font-size: 10pt;
  font-family: "Calibri", sans-serif;
}
p.MsoCommentText,
li.MsoCommentText,
div.MsoCommentText {
  mso-style-link: "Comment Text Char";
  margin: 0in;
  margin-bottom: 0.0001pt;
  font-size: 10pt;
  font-family: "Calibri", sans-serif;
}
p.MsoHeader,
li.MsoHeader,
div.MsoHeader {
  mso-style-link: "Header Char";
  margin: 0in;
  margin-bottom: 0.0001pt;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
p.MsoFooter,
li.MsoFooter,
div.MsoFooter {
  mso-style-link: "Footer Char";
  margin: 0in;
  margin-bottom: 0.0001pt;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
p.MsoTitle,
li.MsoTitle,
div.MsoTitle {
  margin-top: 24pt;
  margin-right: 0in;
  margin-bottom: 6pt;
  margin-left: 0in;
  page-break-after: avoid;
  font-size: 36pt;
  font-family: "Calibri", sans-serif;
  font-weight: bold;
}
p.MsoSubtitle,
li.MsoSubtitle,
div.MsoSubtitle {
  margin-top: 0.25in;
  margin-right: 0in;
  margin-bottom: 4pt;
  margin-left: 0in;
  page-break-after: avoid;
  font-size: 24pt;
  font-family: "Georgia", serif;
  color: #666666;
  font-style: italic;
}
a:link,
span.MsoHyperlink {
  color: #0563c1;
  text-decoration: underline;
}
a:visited,
span.MsoHyperlinkFollowed {
  color: #954f72;
  text-decoration: underline;
}
p.MsoAcetate,
li.MsoAcetate,
div.MsoAcetate {
  mso-style-link: "Balloon Text Char";
  margin: 0in;
  margin-bottom: 0.0001pt;
  font-size: 9pt;
  font-family: "Times New Roman", serif;
}
p.MsoListParagraph,
li.MsoListParagraph,
div.MsoListParagraph {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 0.5in;
  margin-bottom: 0.0001pt;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
p.MsoListParagraphCxSpFirst,
li.MsoListParagraphCxSpFirst,
div.MsoListParagraphCxSpFirst {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 0.5in;
  margin-bottom: 0.0001pt;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
p.MsoListParagraphCxSpMiddle,
li.MsoListParagraphCxSpMiddle,
div.MsoListParagraphCxSpMiddle {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 0.5in;
  margin-bottom: 0.0001pt;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
p.MsoListParagraphCxSpLast,
li.MsoListParagraphCxSpLast,
div.MsoListParagraphCxSpLast {
  margin-top: 0in;
  margin-right: 0in;
  margin-bottom: 0in;
  margin-left: 0.5in;
  margin-bottom: 0.0001pt;
  font-size: 11pt;
  font-family: "Calibri", sans-serif;
}
span.UnresolvedMention1 {
  mso-style-name: "Unresolved Mention1";
  color: #605e5c;
  background: #e1dfdd;
}
span.HeaderChar {
  mso-style-name: "Header Char";
  mso-style-link: Header;
}
span.FooterChar {
  mso-style-name: "Footer Char";
  mso-style-link: Footer;
}
span.CommentTextChar {
  mso-style-name: "Comment Text Char";
  mso-style-link: "Comment Text";
}
span.BalloonTextChar {
  mso-style-name: "Balloon Text Char";
  mso-style-link: "Balloon Text";
  font-family: "Times New Roman", serif;
}
.MsoChpDefault {
  font-family: "Calibri", sans-serif;
}
/* Page Definitions */
@page WordSection1 {
  /* size: 8.5in 11in; */
  margin: 0.5in 0.5in 0.5in 0.5in;
}
div.WordSection1 {
  page: WordSection1;
}

.WordSection1 {
  background-color: white;
  padding-left: 20px;
  text-align: left;
}

/* List Definitions */
ol {
  margin-bottom: 0in;
}
ul {
  margin-bottom: 0in;
}
