.RecommendationCard {
  display: grid;
  grid-template-columns: minmax(auto-fit, 130px) minmax(auto-fill, 50px);
  grid-template-areas: "RecommendationCard__photo RecommendationCard__recommendation";
  grid-area: "RecommendationCard";
  font-size: var(--fontSizeMedium);
  margin-bottom: 30px;
  border-bottom: 3px dotted black;
}

.RecommendationCard img {
  /* display: inline; */
  margin: 10px;
}

.RecommendationCard p {
  margin: 0px 0px 10px;
}

.RecommendationCard__photo img {
  width: 220px;
  border-radius: 50%;
}

.RecommendationCard__recommendation {
  grid-area: "RecommendationCard__recommendation";
  font-size: var(--fontSizeMedium);
}

.RecommendationCard__recommendation a {
  color: rgb(4, 4, 114);
  font-weight: bold;
}

.RecommendationCard__recommender {
  font-size: var(--fontSizeLarge);
  font-weight: bold;
}

.RecommendationCard__recommenderTitle {
  font-size: var(--fontSizeMedium);
  margin-bottom: 10px;
}
