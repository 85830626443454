.Badges {
  grid-area: "Badges";
  text-align: center;
  background-color: var(--background-light);
  padding: 10px;
  border-right: 20px solid black;
}

.Badges img {
  background-color: white;
  height: 150px;
  width: 150px;
  border-radius: 25%;
  margin: 15px;
}
