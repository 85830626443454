.MainContentContainer {
  border-left: 20px solid black;
  border-right: 20px solid black;
}

.MainContent {
  display: grid;
  grid-template-areas: "MainContentHeader" "MainContentBody";
  grid-area: "MainContent";
  text-align: justify;
  background-image: linear-gradient(to right, var(--background-dark), #ffff72);
  color: var(--gradient-black);
  padding: 0px 25px 0px 25px;
  grid-template-rows: minmax(min-content, 1fr);
}

.visible {
  animation: fadeIn 4s;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}
