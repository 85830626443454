.Portfolio {
  display: grid;
  grid-template-columns: minmax(240px, 1fr) minmax(650px, 70%) minmax(
      240px,
      1fr
    );
  grid-template-areas: "SideBar" "MainContent" "Badges";
  grid-area: "Portfolio";
  height: 100%;
}
