.MainContent__Header {
  grid-area: "MainContentHeader";
  font-weight: 100;
  padding: 10px;
  text-align: center;
  text-shadow: 1px 1px #000000;
  /* max-height: 800px; */
}

.MainContent__Header p {
  font-weight: 500;
  font-size: var(--fontSizeLarge);
  margin: 0px;
  color: white;
  /* min-width: 570px; */
}

.MainContent__Header__imgContainer {
  min-height: 300px;
  display: block;
}

.MainContent__Header__headerImage {
  border-radius: 1%;
  width: 800px;
}

.MainContent__Header__Landing {
  border-radius: 5%;
  width: 500px;
}

.Loader {
  width: 800px;
}
