.Highlights {
  font-weight: bold;
  background-color: green;
  color: white;
}

.NamelySense a {
  color: blue;
}

.NamelySenseGoogleDoc {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
}

.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-1 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-1;
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_aulkdkfyvnnj-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_sk7y82vgsp5q-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-6.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-6 0;
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_wgos134f4rvn-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_rbcl76p5ato0-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hxvi2qvw7kvb-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_k20156ckxm8s-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_nw2cxupuup6d-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_hxvi2qvw7kvb-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_aulkdkfyvnnj-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_hj7pkip0tgxt-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-8 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-8;
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_y8m1hau9zzz0-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_y8m1hau9zzz0-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-1.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-1 0;
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_2xgovgskwcdx-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_q9jjm58vpftd-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_t3luv8y9d5rh-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-4 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-4;
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_c4kx25kvtrii-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k50olb6cirkf-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_d5do35ge0o3x-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_896k3g3ixq2n-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-6 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-6;
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_ha5qmdvcb4si-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_czya9fs0uk08-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_oewobgvgj1c8-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-0.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-0 0;
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_i25eulm3wxvl-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_nw2cxupuup6d-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-5 > li:before {
  content: "(" counter(lst-ctn-kix_80gfnmsdh8e7-5, lower-roman) ") ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-4 > li:before {
  content: "(" counter(lst-ctn-kix_80gfnmsdh8e7-4, lower-latin) ") ";
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-1 > li:before {
  content: "" counter(lst-ctn-kix_80gfnmsdh8e7-1, lower-latin) ") ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-3 > li:before {
  content: "(" counter(lst-ctn-kix_80gfnmsdh8e7-3, decimal) ") ";
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-2 > li:before {
  content: "" counter(lst-ctn-kix_80gfnmsdh8e7-2, lower-roman) ") ";
}
.NamelySenseGoogleDoc ul.lst-kix_x0lff8jx46oc-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-0 > li:before {
  content: "" counter(lst-ctn-kix_80gfnmsdh8e7-0, decimal) ") ";
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_37go6g7zza71-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-5.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-5 0;
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-6 > li:before {
  content: "" counter(lst-ctn-kix_80gfnmsdh8e7-6, decimal) ". ";
}
.NamelySenseGoogleDoc ul.lst-kix_48sqb0eak1ob-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-7 > li:before {
  content: "" counter(lst-ctn-kix_80gfnmsdh8e7-7, lower-latin) ". ";
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-8 > li:before {
  content: "" counter(lst-ctn-kix_80gfnmsdh8e7-8, lower-roman) ". ";
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_hj7pkip0tgxt-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_rz8nd6k90owi-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_czya9fs0uk08-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_u8tjog263o2l-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-2.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-2 0;
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-3 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-3;
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_37go6g7zza71-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_srudydmdpi45-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_k20156ckxm8s-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_bm8ydm7t1qpu-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_x0lff8jx46oc-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-3.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-3 0;
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_d5do35ge0o3x-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-0 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-0;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-2 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-2;
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_2xgovgskwcdx-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-4.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-4 0;
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-7.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-7 0;
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-7 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-7;
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_t3luv8y9d5rh-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_gpdwx8hug7j8-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-4 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_80gfnmsdh8e7-5 > li {
  counter-increment: lst-ctn-kix_80gfnmsdh8e7-5;
}
.NamelySenseGoogleDoc .lst-kix_rz8nd6k90owi-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_wgos134f4rvn-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_68mkr2t9zt34-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_ha5qmdvcb4si-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-8 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-6 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-7 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_48sqb0eak1ob-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-4 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-5 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_gpdwx8hug7j8-3 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-2 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_i25eulm3wxvl-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_srudydmdpi45-8 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_u8tjog263o2l-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_c4kx25kvtrii-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_k50olb6cirkf-6 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_oewobgvgj1c8-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ol.lst-kix_80gfnmsdh8e7-8.start {
  counter-reset: lst-ctn-kix_80gfnmsdh8e7-8 0;
}
.NamelySenseGoogleDoc .lst-kix_q9jjm58vpftd-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc .lst-kix_896k3g3ixq2n-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-5 > li:before {
  content: "\0025a0  ";
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-3 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc .lst-kix_68mkr2t9zt34-1 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-0 {
  list-style-type: none;
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-1 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_bm8ydm7t1qpu-7 > li:before {
  content: "\0025cb  ";
}
.NamelySenseGoogleDoc ul.lst-kix_sk7y82vgsp5q-2 {
  list-style-type: none;
}
.NamelySenseGoogleDoc .lst-kix_rbcl76p5ato0-0 > li:before {
  content: "\0025cf  ";
}
.NamelySenseGoogleDoc ol {
  margin: 0;
  padding: 0;
}
.NamelySenseGoogleDoc table td,
.NamelySenseGoogleDoc table th {
  padding: 0;
}
.NamelySenseGoogleDoc .c13 {
  border-right-style: solid;
  padding: 5pt 5pt 5pt 5pt;
  border-bottom-color: #000000;
  border-top-width: 1pt;
  border-right-width: 1pt;
  border-left-color: #000000;
  vertical-align: top;
  border-right-color: #000000;
  border-left-width: 1pt;
  border-top-style: solid;
  border-left-style: solid;
  border-bottom-width: 1pt;
  width: 156pt;
  border-top-color: #000000;
  border-bottom-style: solid;
}
.NamelySenseGoogleDoc .c2 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc .c7 {
  margin-left: 24pt;
  padding-top: 8pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.4285714285714286;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc .c4 {
  margin-left: 74pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc .c5 {
  background-color: #ffffff;
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 15pt;
  font-family: "Arial";
  font-style: normal;
}
.NamelySenseGoogleDoc .c6 {
  background-color: #ffffff;
  color: #333333;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: "Arial";
  font-style: normal;
}
.NamelySenseGoogleDoc .c3 {
  margin-left: 42pt;
  padding-top: 8pt;
  padding-left: 0pt;
  padding-bottom: 0pt;
  line-height: 1.4285714285714286;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc .c14 {
  background-color: #ffffff;
  color: #ff0000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  font-family: "Arial";
  font-style: normal;
}
.NamelySenseGoogleDoc .c0 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.NamelySenseGoogleDoc .c23 {
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.NamelySenseGoogleDoc .c24 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.NamelySenseGoogleDoc .c8 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc .c20 {
  padding-top: 8pt;
  padding-bottom: 0pt;
  line-height: 1.4285714285714286;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc .c15 {
  padding-top: 23pt;
  padding-bottom: 0pt;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc .c17 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-family: "Arial";
  font-style: normal;
}
.NamelySenseGoogleDoc .c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: center;
}
.NamelySenseGoogleDoc .c28 {
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}
.NamelySenseGoogleDoc .c21 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 52pt 52pt 52pt 52pt;
}
.NamelySenseGoogleDoc .c19 {
  background-color: #ffffff;
  font-size: 11.5pt;
  color: #ff0000;
}
.NamelySenseGoogleDoc .c26 {
  background-color: #ffffff;
  color: #000000;
  font-size: 10.5pt;
}
.NamelySenseGoogleDoc .c16 {
  background-color: #ffffff;
  font-size: 10.5pt;
  color: #333333;
}
.NamelySenseGoogleDoc .c25 {
  margin-left: 108pt;
  padding-left: 0pt;
}
.NamelySenseGoogleDoc .c10 {
  margin-left: 52pt;
  padding-left: 0pt;
}
.NamelySenseGoogleDoc .c9 {
  padding: 0;
  margin: 0;
}
.NamelySenseGoogleDoc .c29 {
  background-color: #ffffff;
  font-size: 10.5pt;
}
.NamelySenseGoogleDoc .c11 {
  height: 11pt;
}
.NamelySenseGoogleDoc .c22 {
  height: 16pt;
}
.NamelySenseGoogleDoc .c18 {
  background-color: #000000;
}
.NamelySenseGoogleDoc .c27 {
  font-weight: 700;
}
.NamelySenseGoogleDoc .c12 {
  height: 0pt;
}
.NamelySenseGoogleDoc .title {
  padding-top: 0pt;
  color: #000000;
  font-size: 26pt;
  padding-bottom: 3pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc .subtitle {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc li {
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.NamelySenseGoogleDoc p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: "Arial";
}
.NamelySenseGoogleDoc h1 {
  padding-top: 20pt;
  color: #000000;
  font-size: 20pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc h2 {
  padding-top: 18pt;
  color: #000000;
  font-size: 16pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc h3 {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc h4 {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc h5 {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.NamelySenseGoogleDoc h6 {
  padding-top: 12pt;
  color: #666666;
  font-size: var(--fontSizeSmaller);
  padding-bottom: 4pt;
  font-family: "Arial";
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left;
}
