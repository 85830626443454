:root {
  --gradient-green: #78887a;
  --gradient-teal: #447c7d;
  --gradient-cream: #e5d6cb;
  --gradient-red: #ff0000;
  --gradient-dark: #e2391b;
  --gradient-black: #000000;
  --gradient-sky: #768293;

  --text-light: var(--gradient-cream);
  --background-light: var(--gradient-sky);
  --text-dark: var(--gradient-black);
  --background-dark: var(--gradient-dark);
  --fontSizeHeaderLarge: 7rem;
  --fontSizeHeader: 5rem;
  --fontSizeLarger: 3rem;
  --fontSizeLarge: 2.25rem;
  --fontSizeMediumPlus: 2rem;
  --fontSizeMedium: 1.75rem;
  --fontSizeSmall: 1.25rem;
  --fontSizeSmaller: 1rem;
  --fontSizeSmallest: 0.75rem;
  --businessCardFont: "Gentium Basic";
  --businessCardType: serif;
}

@font-face {
  font-family: "Raleway";
  src: url("./Fonts/Raleway-Regular.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("./Fonts/Raleway-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "RalewayThin";
  src: url("./Fonts/Raleway-Thin.ttf");
}

* {
  font-weight: 300;
}

a {
  color: var(--text-light);
}

.Background {
  background-image: linear-gradient(to right, var(--background-dark), #ffff72);
  background-repeat: no-repeat;
}

#root {
  display: grid;
  grid-template-areas: "NavBarContainer" "RouterUnderNavBar" "Void";
  grid-auto-rows: repeat(auto-fill, minmax(1fr, "2900px"));
  background-image: linear-gradient(to right, var(--background-dark), #ffff72);
  background-repeat: no-repeat;
}

html {
  background-image: linear-gradient(to right, var(--background-dark), #ffff72);
  background-repeat: no-repeat;
}

.Header {
  grid-area: "Header";
}

.Bold {
  font-weight: bold;
}

.Center {
  text-align: center;
}

.Void {
  grid-area: "Void";
  background-color: black;
  min-height: 400px;
}

.Italics {
  font-style: italic;
}

.Underline {
  text-decoration: underline;
}
