.MainContent__Body {
  grid-area: "MainContentBody";
  padding: 5px;
  font-weight: 100;
  /* min-height: 1800px; */
  font-size: var(--fontSizeSmall);
}

.MainContent__Body p {
  margin: 0px;
  margin-bottom: 20px;
}

.MainContent__Body li {
  margin: 0px;
}

.MainContent__Body ul {
  padding: 20px;
  text-align: left;
  margin: 0px;
}
